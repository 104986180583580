<template>
  <div class="import-step-modal">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical">
      <a-form-model-item label="选择应用" prop="application_id">
        <a-select v-model="form.application_id" placeholder="请选择应用" :disabled="!!appId">
          <a-select-option v-for="(item, index) in systemAppList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="选择操作类型" prop="op_type">
        <a-select v-model="form.op_type" placeholder="请选择操作类型" :disabled="opType">
          <a-select-option value="0">操作</a-select-option>
          <a-select-option value="1">触发</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="选择数据格式" prop="dataFormat">
        <div class="data-format">
          <a-radio-group name="radioGroup" v-model="form.dataFormat">
            <a-radio v-for="(item, index) in dataFomatList" class="radio-item" :key="index" :value="item.value">{{ item.label }}</a-radio>
          </a-radio-group>
          <div class="tips">
            <a-icon class="tips-icon" type="info-circle" />支持导入 OpenAPI 3、Swagger1、2、3 数据格式的 Json 或 yaml 文件。
            <!-- <span class="see">查看详细说明></span>> -->
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="文件方式导入" prop="file">
        <a-upload-dragger name="file" :multiple="false" :showUploadList="false" @change="handleChange" :accept="fileType" :remove="handleRemove" :beforeUpload="beforeUpload">
          <span v-if="!file">
            <div class="file-icon"><a-icon class="file-icon-upload" type="cloud-upload" /></div>
            <span class="file-tips">点击或拖拽文件到本区域导入</span>
          </span>
          <span v-else>
            <div class="file-icon" style="height: 34px; line-height: 34px"> 当前选择文件：{{file.name}}</div>
            <span class="file-tips">点击或拖拽文件到本区域更换文件</span>
          </span>
        </a-upload-dragger>
      </a-form-model-item>
      <div class="step-footer">
        <a-button class="mgr12" @click="resetForm">取消</a-button>
        <a-button type="primary" @click="onSubmit">导入</a-button>
      </div>
    </a-form-model>
  </div>
</template>
<script>
export default {
  props: {
    appId: [String, Number],
    opType: [String, Number],
    callBack: Function,
    showPage: String,
  },
  data() {
    return {
      fileType: ".xls, .xlsx, .csv, .json, .txt",
      fileList: [],
      file: null,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      systemAppList: [],
      dataFomatList: [
        {
          label: "OpenAPI/Swagger",
          value: "OpenAPI/Swagger",
        },
        {
          label: "Posterman",
          value: "Posterman",
        },
        {
          label: "Apifox",
          value: "Apifox",
        },
        {
          label: "HAR",
          value: "HAR",
        },
        {
          label: "YApi",
          value: "YApi",
        },
        {
          label: "Eonlinker",
          value: "Eonlinker",
        },
        {
          label: "DoClever",
          value: "DoClever",
        },
        {
          label: "SosoApi",
          value: "SosoApi",
        },
        {
          label: "Apizza",
          value: "Apizza",
        },
        {
          label: "Apidoc",
          value: "Apidoc",
        },
        {
          label: "RAML",
          value: "RAML",
        },
        {
          label: "SosoApi",
          value: "SosoApi",
        },
        {
          label: "RAP2",
          value: "RAP2",
        },
        {
          label: "ApiPost",
          value: "ApiPost",
        },
        {
          label: "WADL",
          value: "WADL",
        },
      ],
      form: {
        application_id: undefined,
        dataFormat: "OpenAPI/Swagger",
        op_type: "0",
        file: null
      },
      rules: {
        application_id: [{ required: true, message: '请选择应用', trigger: 'change' }],
        op_type: [{ required: true, message: '请选择操作类型', trigger: 'change' }],
        // file: [{ required: true, message: '请选择操作类型', trigger: 'change' }]
      },
    };
  },
  created() {
    this.getApp();
    this.form.op_type = this.opType || undefined
  },
  methods: {
    getApp() {
      const params = {
        page_size: 1000,
        page: 1,
        kind: -1,
      };
      const self = this;
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          self.systemAppList = res.data.results;
          self.form.application_id = self.appId || undefined
        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      // if (file.size > 10 * 1024 * 1000) {
      //   this.$message.error("文件上传最大仅支持10M");
      //   return;
      // }
      this.fileList = [file];
      return false;
    },
    handleChange(e) {
      // if(e.file.size > (10*1024*1000)){
      //   return
      // }
      this.file = e.file;
      this.form.file = e.file
    },
    hostsImport() {
      if (!this.form.file) return this.$message.error('请导入文件')
      const self = this;
      self.loading = true;
      let formdata = new FormData();
      formdata.append("file", this.form.file);
      this.$axiosFormData(global.API.importStep + '?application_id=' + this.appId, formdata)
        .then((res) => {
          self.loading = false;
          if ([200, 201, 202, 204].includes(res.status)) {
            if (res.data.code === 200) {
              self.resetForm()
              if (self.showPage) {
                self.callBack(self.showPage)
              } else {
                self.callBack()
              }
            } else {
              self.$message.error(res.data.msg);
            }
          }
        })
        .catch((res) => {
          self.loading = false;
        });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
         this.hostsImport()
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$destroyAll();
    },
  },
};
</script>
<style lang="less" scoped>
.import-step-modal {
  padding: 12px 30px 12px 30px;
  /deep/ .ant-form-item-label > label {
    font-size: 14px;
    color: #1d202d;
    letter-spacing: 0;
    font-weight: 500;
    &::before {
      margin-right: 0;
      content: "";
    }
  }
  .data-format {
    background: #ffffff;
    border: 1px solid rgba(207, 213, 222, 1);
    border-radius: 6px;
    padding: 12px 0px 12px 6px;
    .radio-item {
      width: 166px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-right: 1px solid #cfd5de;
      padding-left: 14px;
      margin-right: 0;
      &:nth-child(4n) {
        border-right: none;
      }
    }
    .tips {
      margin: 12px 20px 0 14px;
      padding: 0 13px;
      height: 40px;
      line-height: 40px;
      background: #ebf4ff;
      border-radius: 6px;
      font-size: 14px;
      color: #34343c;
      span {
        color: #3974f4;
        cursor: pointer;
      }
      &-icon {
        color: #3974f4;
        margin-right: 8px;
      }
    }
  }
  /deep/ .ant-upload.ant-upload-drag {
    background: #FFFFFF;
    border: 1px solid rgba(207,213,222,1);
    border-radius: 4px;
    padding: 10px 0;
    .file-icon {
      &-upload {
        font-size: 32px;
      }
    }
    span.file-tips {
      margin-top: 10px;
      display: block;
      font-size: 14px;
      color: #7B7F8A;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .step-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
